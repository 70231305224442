import clsx from 'clsx'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import KanbanLaneItem from '@/components/shared/components/kanban/KanbanLaneItem'
import type {
  IKanbanLaneItem,
  IKanbanLaneWithItems,
} from '@/components/shared/components/kanban/types'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Spinner from '@/components/shared/ui/Loader'

export interface IKanbanLanePropTypes {
  lane: IKanbanLaneWithItems
  itemRenderer?: (item: IKanbanLaneItem) => JSX.Element
  laneHeaderRenderer?: (lane: IKanbanLaneWithItems) => JSX.Element
  subLaneHeaderRenderer?: (lane: IKanbanLaneWithItems) => JSX.Element
  emptyListRenderer?: (lane: IKanbanLaneWithItems) => JSX.Element
  loading?: boolean
  canFetchMore?: boolean
  onFetchMore?: (lane: IKanbanLaneWithItems) => void
  readonly?: boolean
  className?: {
    laneContainer?: string
  }
}
export default function KanbanLane({
  lane,
  itemRenderer,
  laneHeaderRenderer,
  subLaneHeaderRenderer,
  emptyListRenderer = () => <></>,
  loading,
  canFetchMore,
  onFetchMore,
  readonly,
  className,
}: IKanbanLanePropTypes) {
  const renderItem = (item: IKanbanLaneItem) => {
    return (
      <KanbanLaneItem
        key={`${lane.id}_${lane.name}_${item.id}`}
        item={item}
        itemRenderer={itemRenderer}
        readonly={readonly}
      />
    )
  }

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'hsl(var(--gray4V)' : '',
    borderRadius: '0.50rem',
    paddingBottom: isDraggingOver ? '90px' : '',
    border: isDraggingOver ? '1px dotted hsl(var(--gray8V)' : '',
    gap: 4,
  })

  return (
    <div className='my-2 max-h-[100%] min-w-[325px] max-w-[325px] snap-center snap-always overflow-hidden rounded-md border border-gray4 bg-gray2'>
      {laneHeaderRenderer ? (
        laneHeaderRenderer(lane)
      ) : (
        <div className='border-b border-gray5 px-3 py-2 '>
          <span className='font-semibold'>{lane.title}</span>
        </div>
      )}
      <div
        className={clsx(
          'mt-1 space-y-3.5 overflow-y-auto p-2 py-1.5 scrollbar-hide',
          className?.laneContainer
        )}
      >
        {lane.subLanes ? (
          <>
            {lane.subLanes.map((subLane, index) => (
              <div
                className='space-y-3.5 rounded-md border border-gray5 bg-snow p-2 py-3 dark:bg-gray4  '
                key={index}
              >
                {subLaneHeaderRenderer ? (
                  subLaneHeaderRenderer(subLane)
                ) : (
                  <div className='flex items-center justify-between py-1'>
                    <span className='text-xs font-semibold'>{lane.title}</span>
                  </div>
                )}
                <Droppable key={subLane.id} droppableId={subLane.id}>
                  {(provided, snapshot) => (
                    <div
                      className='w-full space-y-3'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {subLane.items.length
                        ? subLane.items.map(renderItem)
                        : emptyListRenderer(subLane)}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </>
        ) : (
          <>
            <Droppable droppableId={lane.id}>
              {(provided, snapshot) => (
                <div
                  className='w-full space-y-3'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {lane.items.length || loading
                    ? lane.items.map(renderItem)
                    : emptyListRenderer(lane)}
                  {loading && !lane.items.length && <Spinner />}
                </div>
              )}
            </Droppable>
            <InfiniteScroll
              showIf={!!canFetchMore}
              canFetchMore={!!canFetchMore}
              onIntersect={() => {
                onFetchMore?.(lane)
              }}
              loaderComponent={
                <div className='flex justify-center'>
                  <SpinnerIcon className='h-6 w-6 animate-spin' />
                </div>
              }
              threshold={0.5}
            />
          </>
        )}
      </div>
    </div>
  )
}
