import clsx from 'clsx'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'

import type { IKanbanLanePropTypes } from '@/components/shared/components/kanban/KanbanLane'
import KanbanLane from '@/components/shared/components/kanban/KanbanLane'
import type {
  IKanbanLane,
  IKanbanLaneItem,
  IKanbanLaneWithItems,
} from '@/components/shared/components/kanban/types'

export interface IKanbanRef {
  toggleEdit: () => boolean
}
export interface IKanbanPropTypes {
  className?: string
  lanes: IKanbanLane[]
  subLanes?: IKanbanLane[]
  items: IKanbanLaneItem[]
  onDragEnd: (result: DropResult) => void
  itemRenderer?: (item: IKanbanLaneItem) => JSX.Element
  laneHeaderRenderer?: (
    lane: IKanbanLaneWithItems,
    config: {
      edit: boolean
    }
  ) => JSX.Element
  subLaneHeaderRenderer?: (lane: IKanbanLaneWithItems) => JSX.Element
  emptyListRenderer?: (lane: IKanbanLaneWithItems) => JSX.Element
  addLaneRenderer?: () => JSX.Element
  laneLoading?: { [key: string]: boolean }
  laneCanFetchMore?: { [key: string]: boolean }
  onFetchMore?: (lane: IKanbanLaneWithItems) => void
  readonly?: boolean
  renderEmpty?: () => JSX.Element
  classNames?: {
    lane: IKanbanLanePropTypes['className']
  }
}
const Kanban = forwardRef(
  (props: IKanbanPropTypes, ref: ForwardedRef<IKanbanRef>) => {
    const {
      className,
      lanes: _lanes,
      items: _items,
      subLanes: _subLanes,
      onDragEnd,
      itemRenderer,
      laneHeaderRenderer,
      subLaneHeaderRenderer,
      emptyListRenderer,
      addLaneRenderer,
      laneLoading,
      laneCanFetchMore,
      onFetchMore,
      readonly,
      classNames,
      renderEmpty = () => <></>,
    } = props
    const [edit, setEdit] = useState(false)

    const [lanes, setLanes] = React.useState<IKanbanLane[]>(_lanes || [])
    const [subLanes, setSubLanes] = React.useState<IKanbanLane[] | undefined>(
      _subLanes
    )
    const [items, setItems] = React.useState<IKanbanLaneItem[]>(_items || [])

    const subLanesWithItems: IKanbanLaneWithItems[] | undefined = useMemo(
      () =>
        subLanes
          ? subLanes.map((lane) => ({
              ...lane,
              items: items
                .filter((item) => item.subLaneId === lane.id)
                .map((_item, index) => ({ ..._item, index }))
                .sort((a, b) => a.index || 0 - b.index || 0),
            }))
          : undefined,
      [subLanes, items]
    )

    const lanesWithItems: IKanbanLaneWithItems[] = useMemo(
      () =>
        lanes.map((lane) => ({
          ...lane,
          subLanes: subLanesWithItems
            ? subLanesWithItems.filter((subLane) => subLane.laneId === lane.id)
            : undefined,

          items: items
            .filter((item) => item.laneId.toString() === lane.id.toString())
            .map((_item, index) => ({ ..._item, index }))
            .sort((a, b) => a.index || 0 - b.index || 0),
        })),
      [lanes, items]
    )

    useEffect(() => {
      setLanes(_lanes)
    }, [_lanes])

    useEffect(() => {
      setItems(_items)
    }, [_items])

    useEffect(() => {
      setSubLanes(_subLanes)
    }, [_subLanes])

    useImperativeHandle(ref, () => ({
      toggleEdit: () => {
        const newState = !edit
        setEdit(newState)
        return newState
      },
    }))

    const renderLane = (lane: IKanbanLaneWithItems) => {
      return (
        <KanbanLane
          key={lane.id + lane.name}
          lane={lane}
          itemRenderer={itemRenderer}
          laneHeaderRenderer={
            laneHeaderRenderer
              ? (_lane) =>
                  laneHeaderRenderer?.(_lane, {
                    edit,
                  })
              : undefined
          }
          subLaneHeaderRenderer={subLaneHeaderRenderer}
          emptyListRenderer={emptyListRenderer}
          loading={laneLoading?.[lane.id]}
          canFetchMore={laneCanFetchMore?.[lane.id]}
          onFetchMore={onFetchMore}
          readonly={readonly}
          className={classNames?.lane}
        />
      )
    }

    return (
      // <div className='flex justify-center'>
      lanesWithItems.length ? (
        <div
          className={clsx(
            'mt-4 flex snap-x snap-mandatory items-start gap-3 overflow-x-auto overflow-y-hidden px-4',
            readonly
              ? 'max-h-[calc(100vh-104px)] min-h-full'
              : 'max-h-[calc(100vh-90px)] min-h-[calc(100vh-90px)]',
            className
          )}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {lanesWithItems.map(renderLane)}
          </DragDropContext>

          {addLaneRenderer ? addLaneRenderer() : <></>}
        </div>
      ) : (
        // </div>
        renderEmpty?.()
      )
    )
  }
)

Kanban.displayName = 'Kanban'
export default Kanban
