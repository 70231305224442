import { Pencil } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import Status from '@/components/posts/Status'
import type { IKanbanLaneWithItems } from '@/components/shared/components/kanban/types'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Input from '@/components/shared/ui/Input'
import { STATUS_COLORS_MAP } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import { templateStringReplace } from '@/lib/helpers/stringHelpers'

interface IPropTypes {
  lane: IKanbanLaneWithItems
  config?: {
    edit: boolean
  }
  onLaneToggle?: (lane: IKanbanLaneWithItems, state: boolean) => Promise<any>
  onLaneRename?: (lane: IKanbanLaneWithItems, name: string) => Promise<any>
}
export default function RoadmapLaneHeader({
  lane,
  config,
  onLaneToggle,
  onLaneRename,
}: IPropTypes) {
  const t = useTranslations()
  const { isAdmin, organizationCustomization } = useContext(HNContext)

  const [showForm, setShowForm] = useState(false)
  const [laneConfig, setLaneConfig] = useState<{ edit: boolean }>(
    config || { edit: false }
  )

  const [formData, setFormData] = useState({
    name: lane.name,
  })
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onLaneRename?.(lane, formData.name).then(() => setShowForm(false))
  }

  const alertTexts = t(
    `roadmap.headerActions.toggleVisibilty.${
      lane.enabled ? 'disableAlertTexts' : 'alertTexts'
    }`
  )

  const statusStyles =
    STATUS_COLORS_MAP[lane.id] ||
    STATUS_COLORS_MAP[lane.parentId] ||
    STATUS_COLORS_MAP.default

  useEffect(() => {
    if (isAdmin && organizationCustomization?.roadmap_type === 'custom') {
      setLaneConfig({ edit: true })
      setShowForm(false)
    } else if (config) {
      setLaneConfig(config)
    }
    return () => {
      setLaneConfig({ edit: false })
      setShowForm(false)
    }
  }, [config])

  const showIcon = useMemo(() => {
    return lane.type !== 'quarterly' && lane.type !== 'monthly'
  }, [lane])

  return (
    <div className='my-2 w-full items-center justify-between  border-b border-gray5 pb-1.5 '>
      <div className='w-full'>
        {!showForm ? (
          <div className='flex w-full items-center justify-between px-3'>
            <div className='flex items-center space-x-1'>
              {showIcon && (
                <Status
                  status={{
                    ...lane,
                    name: lane.name,
                    color: lane.color || statusStyles?.icon,
                    status: lane.parentId || lane.id,
                    value: lane.id,
                  }}
                  iconOnly
                />
              )}
              <span
                className={clsx('font-semibold', statusStyles?.text)}
                style={{
                  color: lane.color,
                }}
              >
                {lane.name}{' '}
                {!isNullOrUndefined(lane.count) && `(${lane.count})`}
              </span>
            </div>
            <div className='flex items-center'>
              {laneConfig?.edit && lane.canEditName && (
                <Button
                  variant='naked'
                  onClick={() => setShowForm(!showForm)}
                  icon={<Pencil />}
                />
              )}
              {laneConfig?.edit && !showForm && showIcon && (
                <Checkbox
                  isSwitch
                  alertTexts={{
                    title: alertTexts.title,
                    description: templateStringReplace(alertTexts.description, {
                      columnName: lane.name,
                    }),
                  }}
                  onCheckedChange={(view) => onLaneToggle?.(lane, view)}
                  checked={lane.visible_in_roadmap}
                />
              )}
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className='mb-2 w-full px-2'>
            <Input
              defaultValue={lane.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, name: e.target.value })
              }}
            />
            <div className='mt-2 flex items-center justify-end space-x-1'>
              <Button variant='primary' size='xxs'>
                {t('buttons.update', { ignorePrefix: true })}
              </Button>
              <Button
                variant='secondary'
                size='xxs'
                onClick={() => setShowForm(!showForm)}
              >
                {t('buttons.cancel', { ignorePrefix: true })}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
