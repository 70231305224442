import type { IBoardTemplate } from '@/components/organization/create/boards/boardsList'
import {
  ACCEPT_OTL_LINK_PATH,
  ADMIN_BOARD_LIST_PATH,
  ADMIN_BOARD_SLUG_CHECK,
  ADMIN_GLOBAL_BOARD_COMMENT_CUSTOMIZATION,
  ADMIN_GLOBAL_BOARD_CUSTOMIZATION,
  ADMIN_GLOBAL_BOARD_FORM_CUSTOMIZATION,
  ADMIN_GLOBAL_BOARD_SETTINGS,
  ADMIN_SINGLE_BOARD_ANALYSIS_PATH,
  ADMIN_SINGLE_BOARD_GENERATE_INVITE_PATH,
  ADMIN_SINGLE_BOARD_INTEGRATION_PATH,
  ADMIN_SINGLE_BOARD_MEMBERS_PATH,
  ADMIN_SINGLE_BOARD_MEMBERS_REMOVE_PATH,
  ADMIN_SINGLE_BOARD_OPTIONS_PATH,
  ADMIN_SINGLE_BOARD_PATH,
  ADMIN_SINGLE_BOARD_STATS_PATH,
  BULK_IMPORT_BOARDS,
  CANNED_RESPONSE_PATH,
  CANNED_RESPONSES_PATH,
  CUSTOM_STATUS_PATH,
  GENERATE_WIDGET_KEY_PATH,
  GET_CUSTOM_STATUS_PATH,
  LETTERBOX_EMAIL_PATH,
  SUBMISSION_FORM_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import type { IGlobalBoardSettings } from '@/types/app'
import type {
  IBoardIntegrationDetails,
  IBoardListAPIParams,
  IBoardMember,
  IBoardMemberListAPIParams,
  ICreateBoardData,
  IGlobalFormCustomizationItems,
  ISingleBoardStats,
} from '@/types/board'
import type { IKeyValueMap, IRequestHeaders } from '@/types/common'
import type { IStatus } from '@/types/post'
import type { ISettingUpdatePayload } from '@/types/settings'

import type {
  ICannedResponse,
  ICannedResponseListAPIParams,
  ICreateCannedResponseData,
} from './CannedResponse'

export const getGlobalBoardsSettings = (): Promise<IGlobalBoardSettings> =>
  API.get(ADMIN_GLOBAL_BOARD_SETTINGS)

export const updateGlobalBoardsSettings = (
  params: ISettingUpdatePayload
): Promise<IGlobalBoardSettings> => API.put(ADMIN_GLOBAL_BOARD_SETTINGS, params)
export const updateGlobalBoardsFormCustomization = (
  id: string,
  params: IKeyValueMap
): Promise<IGlobalFormCustomizationItems> =>
  API.put(ADMIN_GLOBAL_BOARD_FORM_CUSTOMIZATION(id), params)
export const updateGlobalBoardsCustomization = (
  params: ISettingUpdatePayload
): Promise<IGlobalFormCustomizationItems> =>
  API.put(ADMIN_GLOBAL_BOARD_CUSTOMIZATION, params)

export const updateGlobalBoardsCommentCustomization = (
  params: ISettingUpdatePayload
): Promise<IGlobalFormCustomizationItems> =>
  API.post(ADMIN_GLOBAL_BOARD_COMMENT_CUSTOMIZATION, params)

// Admin Side
export const getAdminBoards = (filters: IBoardListAPIParams = {}) => {
  return API.get(ADMIN_BOARD_LIST_PATH, filters)
}
export const createBoard = (data: ICreateBoardData) => {
  return API.post(ADMIN_BOARD_LIST_PATH, data)
}
export const getAdminBoard = (name: string) =>
  API.get(ADMIN_SINGLE_BOARD_PATH(name))

export const deleteAdminBoard = (name: string) =>
  API.delete(ADMIN_SINGLE_BOARD_PATH(name))

export const updateAdminBoard = (name: string, data: IKeyValueMap) =>
  API.put(ADMIN_SINGLE_BOARD_PATH(name), data)

export const updateCommentCustomization = (name: string, data: IKeyValueMap) =>
  API.put(ADMIN_SINGLE_BOARD_PATH(name), data)

export const updateAdminBoardOptions = (name: string, data: IKeyValueMap) => {
  return API.put(ADMIN_SINGLE_BOARD_OPTIONS_PATH(name), data)
}

export const getAdminBoardIntegrations = (
  name: string
): Promise<IBoardIntegrationDetails> =>
  API.get(ADMIN_SINGLE_BOARD_INTEGRATION_PATH(name))

export const getAdminBoardStats = (id: string): Promise<ISingleBoardStats> => {
  return API.get(ADMIN_SINGLE_BOARD_STATS_PATH, { bucket_id: id }).then(
    (data) => data.counts_data.counts
  )
}

export const checkBoardSlug = (slug: string) => {
  return API.get(ADMIN_BOARD_SLUG_CHECK, { bucket_slug: slug })
}

export const generateWidgetKey = () => API.post(GENERATE_WIDGET_KEY_PATH)

// Canned Responses
export const createCannedResponse = (data: ICreateCannedResponseData) =>
  API.post(CANNED_RESPONSES_PATH, data)
export const getCannedResponses = (params: ICannedResponseListAPIParams) =>
  API.get(CANNED_RESPONSES_PATH, params)
export const updateCannedResponse = (
  id: string,
  data: ICreateCannedResponseData
): Promise<ICannedResponse> => API.put(CANNED_RESPONSE_PATH(id), data)
export const deleteCannedResponse = (id: string) =>
  API.delete(CANNED_RESPONSE_PATH(id))

// Custom States
export const getCustomStatuses = () => API.get(GET_CUSTOM_STATUS_PATH)
export const addCustomStatuses = (data: IStatus) =>
  API.post(GET_CUSTOM_STATUS_PATH, data)
export const updateCustomStatuses = (id: string, data: IStatus) =>
  API.put(CUSTOM_STATUS_PATH(id), data)
export const removeCustomStatuses = (id: string) =>
  API.delete(CUSTOM_STATUS_PATH(id))

// Members
export const getBoardMembers = (
  name: string,
  filters: IBoardMemberListAPIParams
) => {
  return API.get(ADMIN_SINGLE_BOARD_MEMBERS_PATH(name), filters).then(
    (data) => data.members
  )
}
export const removeBoardMember = (name: string, id: string) => {
  return API.delete(ADMIN_SINGLE_BOARD_MEMBERS_REMOVE_PATH(name, id)).then(
    (data) => data.members
  )
}
export const addBoardMember = (
  name: string,
  data: { email: string }
): Promise<IBoardMember> => {
  return API.post(ADMIN_SINGLE_BOARD_MEMBERS_PATH(name), data)
}

export const generateBoardInviteLink = (name: string) => {
  return API.post(ADMIN_SINGLE_BOARD_GENERATE_INVITE_PATH(name))
}

export const acceptInviteLink = (name: string, key: string) => {
  return API.post(ACCEPT_OTL_LINK_PATH(name, key))
}

export const getSubmissionFormData = (key: string) => {
  return API.get(SUBMISSION_FORM_PATH(key))
}

export const createBoardLetterboxEmail = (slug: string) => {
  return API.post(LETTERBOX_EMAIL_PATH(slug))
}

export const updateBoardLetterbox = (slug: string) => {
  return API.put(LETTERBOX_EMAIL_PATH(slug))
}

export const bulkCreateBoards = (
  data: IBoardTemplate[],
  headers: IRequestHeaders
) => {
  return API.post(
    BULK_IMPORT_BOARDS,
    { buckets: data },
    {
      headers,
    }
  )
}

export const aiOptIn = (boardName: string, data: IKeyValueMap) => {
  return API.put(ADMIN_SINGLE_BOARD_OPTIONS_PATH(boardName), data)
}

export const processPosts = (boardName: string) => {
  return API.post(ADMIN_SINGLE_BOARD_ANALYSIS_PATH(boardName))
}
