import React from 'react'
import type { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

import type { IKanbanLaneItem } from '@/components/shared/components/kanban/types'

interface IPropTypes {
  item: IKanbanLaneItem
  itemRenderer?: (item: IKanbanLaneItem) => JSX.Element
  readonly?: boolean
}

export default function KanbanLaneItem({
  item,
  itemRenderer,
  readonly = false,
}: IPropTypes) {
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '' : '',
    borderRadius: '5px',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  return (
    <Draggable
      isDragDisabled={readonly}
      key={item.id}
      draggableId={item.id}
      index={item.index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {itemRenderer
            ? itemRenderer({ ...item, isDragging: snapshot.isDragging })
            : item.title}
        </div>
      )}
    </Draggable>
  )
}
