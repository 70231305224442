import { Plus } from '@phosphor-icons/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import type { DropResult } from 'react-beautiful-dnd'

import NewPostButton from '@/components/posts/newPost/NewPostButton'
import type { IStatus } from '@/components/posts/Status'
import Status from '@/components/posts/Status'
import RoadmapItemKanbanCard from '@/components/roadmap/multipleRoadmap/kanban/RoadmapItemKanbanCard'
import RoadmapLaneHeader from '@/components/roadmap/multipleRoadmap/kanban/RoadmapLaneHeader'
import type { IKanbanRef } from '@/components/shared/components/kanban/Kanban'
import Kanban from '@/components/shared/components/kanban/Kanban'
import type {
  IKanbanLaneItem,
  IKanbanLaneWithItems,
} from '@/components/shared/components/kanban/types'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import { updateCustomStatuses } from '@/models/Board'
import { updateAdminPostStatus } from '@/models/Post'
import {
  updateRoadmapSettings,
  updateUserViewPreference,
} from '@/models/Roadmap'
import type { MakeOptional } from '@/types/common'
import type { IPostCustomStatus } from '@/types/post'
import type {
  IRoadmap,
  IRoadmapOption,
  IRoadmapSingleViewProp,
} from '@/types/roadmap'

export default function RoadmapSingleKanban({
  roadmap,
  items: roadmapItems,
  lanes = [],
  onUpdate,
  onTagPost,
  readonly,
  canAdd,
  laneLoading,
  type,
  onEditToggle,
  laneCanFetchMore,
  onFetchMore,
  updateRoadmap,
  className,
  groupMechanism,
  classNames,
}: MakeOptional<IRoadmapSingleViewProp, 'roadmap'> & {
  roadmap?: IRoadmap
  type?: string
  onEditToggle?: (edit: boolean) => void
  laneCanFetchMore?: { [key: string]: boolean }
  onFetchMore?: (lane: IKanbanLaneWithItems) => void
  updateRoadmap?: (roadmap: IRoadmap) => void
}) {
  const t = useTranslations('roadmap')
  const [edit, setEdit] = useState(false)
  const kanbanRef = useRef<IKanbanRef>(null)
  const {
    custom_states = [],
    organizationSetting,
    updateContext,
  } = useContext(HNContext)

  const items = useMemo(() => {
    return roadmapItems.map((item, i) => ({
      ...item,
      index: i,
      id: item.id.toString(),
      title: item.title,
      laneId: item.laneId as string,
    }))
  }, [roadmapItems])

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (result.destination.droppableId === result.source.droppableId) return
    const item = roadmapItems.find((itm) => +itm.id === +result.draggableId)

    const newCustomState = custom_states.find(
      (cs) => cs.slug === result.destination?.droppableId
    )
    if (!item || !newCustomState) return

    onUpdate({
      ...item,
      custom_status: {
        ...newCustomState,
        title: newCustomState.name,
        value: newCustomState.slug,
      } as IPostCustomStatus,
      laneId: result.destination.droppableId,
    })

    updateAdminPostStatus(item?.slug, {
      status: result.destination.droppableId,
    } as any).then((updatedPost) =>
      onUpdate({
        ...item,
        ...updatedPost,
        laneId: result.destination?.droppableId || item.laneId,
      })
    )
  }

  const handleConfigPush = (data: any) => {
    if (data.action === 'TOGGLE_ROADMAP_EDIT') {
      const newEdit = kanbanRef.current?.toggleEdit()
      setEdit(!!newEdit)
    }
  }

  const handleToggleLane = (lane: IKanbanLaneWithItems, state: boolean) => {
    if (type === 'default') {
      if (groupMechanism === 'state') {
        return updateCustomStatuses(lane.stateId, {
          visible_in_roadmap: state,
        }).then(() => {
          updateContext?.({
            custom_states: custom_states.map((cs) =>
              cs.slug === lane.id ? { ...cs, visible_in_roadmap: state } : cs
            ),
          })
        })
      }
      if (groupMechanism === 'status') {
        return updateRoadmapSettings({
          [`roadmap_${lane.id}_view`]: state,
        }).then((data) => {
          updateContext?.({
            organizationSetting: { ...organizationSetting, ...data },
          })
        })
      }
    }

    if (!roadmap) return Promise.resolve()

    const laneCustomizationKey: keyof IRoadmapOption =
      roadmap.kanban_view_group === 'state'
        ? 'lane_customization_state'
        : 'lane_customization'
    const laneCustomization = roadmap?.options[laneCustomizationKey] || {}
    const newCustomization = {
      ...laneCustomization,
      [lane.id]: {
        ...laneCustomization[lane.id as keyof typeof laneCustomization],
        enabled: state,
      },
    }

    return updateUserViewPreference(roadmap.id, {
      [laneCustomizationKey]: newCustomization,
    }).then(() =>
      updateRoadmap?.({
        ...roadmap,
        options: {
          ...roadmap?.options,
          [laneCustomizationKey]: newCustomization,
        },
      })
    )
  }

  const handleRenameLane = (lane: IKanbanLaneWithItems, name: string) => {
    if (type === 'default') {
      return updateRoadmapSettings({
        [`roadmap_${lane.id}_name`]: name,
      }).then((data) => {
        updateContext?.({
          organizationSetting: { ...organizationSetting, ...data },
        })
      })
    }
    if (!roadmap) return Promise.resolve()
    const laneCustomization = roadmap?.options.lane_customization || {}
    const newCustomization = {
      ...laneCustomization,
      [lane.id]: {
        ...laneCustomization[lane.id as keyof typeof laneCustomization],
        name,
      },
    }
    return updateUserViewPreference(roadmap.id, {
      lane_customization: newCustomization,
    }).then(() =>
      updateRoadmap?.({
        ...roadmap,
        options: { ...roadmap?.options, lane_customization: newCustomization },
      })
    )
  }

  const handleEntityUpdate = useCallback(
    (eventData: IEntityUpdateEventData) => {
      const { actionType, entity, data } = eventData

      if (entity !== ENTITIES.POSTS) return
      if (actionType === EVENT_ACTION_TYPES.UPDATE) {
        const updatedItem = items.find(
          (item) => item.id.toString() === data.data.id.toString()
        )

        if (!updatedItem) return
        onUpdate({ ...updatedItem, ...data.data })
      }
    },
    [items]
  )

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleConfigPush)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleConfigPush)
    }
  }, [])

  useEffect(() => {
    EventEmitter.subscribe('ENTITY_UPDATE', handleEntityUpdate)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleEntityUpdate)
    }
  }, [items])

  useEffect(() => {
    onEditToggle?.(edit)
  }, [edit])

  const renderItem = (item: IKanbanLaneItem) => (
    <RoadmapItemKanbanCard
      item={item}
      key={item.id}
      isUnderSublane={!(readonly || type === 'default')}
    />
  )

  const renderLaneHeader = (
    lane: IKanbanLaneWithItems,
    config: { edit: boolean }
  ) => {
    return (
      <RoadmapLaneHeader
        lane={lane}
        config={config}
        onLaneToggle={handleToggleLane}
        onLaneRename={handleRenameLane}
      />
    )
  }

  const renderSubLaneHeader = (lane: IKanbanLaneWithItems) => (
    <div className='flex items-center justify-between'>
      <span className='flex items-center justify-between space-x-1 text-xs font-semibold'>
        <Status
          iconOnly
          status={{ ...lane, value: lane?.value || '' } as IStatus}
        />
        <span style={{ color: lane.color }}>
          {lane.title} {!isNullOrUndefined(lane.count) && `(${lane.count})`}
        </span>
      </span>
      {!!(!readonly && canAdd) && (
        <NewPostButton
          onCreate={onTagPost}
          additionalFields={{
            assignee: true,
            status: true,
            etc: true,
          }}
          defaultData={{
            state: lane.id,
          }}
        >
          <Button size='xs' variant='dashed' fab>
            <Plus />
          </Button>
        </NewPostButton>
      )}
    </div>
  )

  const renderEmptyList = () => {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <EmptyState title={t('messages.noPosts.title')} />
      </div>
    )
  }

  return (
    <Kanban
      ref={kanbanRef}
      lanes={lanes}
      items={items}
      readonly={readonly}
      onDragEnd={handleDragEnd}
      itemRenderer={renderItem}
      subLaneHeaderRenderer={renderSubLaneHeader}
      laneHeaderRenderer={renderLaneHeader}
      emptyListRenderer={renderEmptyList}
      laneLoading={laneLoading}
      laneCanFetchMore={laneCanFetchMore}
      onFetchMore={onFetchMore}
      className={className}
      classNames={classNames}
      renderEmpty={() => <EmptyState title={t('messages.noLanes.title')} />}
    />
  )
}
