import { ArrowRight } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import DownvoteButton from '@/components/posts/DownvoteButton'
import PostLink from '@/components/posts/PostLink'
import Status from '@/components/posts/Status'
import UpvoteButton from '@/components/posts/UpvoteButton'
import type { IKanbanLaneItem } from '@/components/shared/components/kanban/types'
import UserAvatar from '@/components/shared/components/UserAvatar'
import Badge from '@/components/shared/ui/Badge'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import { humaizeScore } from '@/lib/helpers/modules/roadmapHelper'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'

interface IPropTypes {
  item: IKanbanLaneItem
  isUnderSublane?: boolean
}

const SHOW_STATUS_VIEW = ['quarterly', 'monthly']
export default function RoadmapItemKanbanCard({
  item,
  isUnderSublane,
}: IPropTypes) {
  const { query } = useRouter()
  const t = useTranslations()
  const { organizationSetting, organizationCustomization, isAdmin } =
    useContext(HNContext)

  const currentView = useMemo(() => {
    return (
      query?.type || organizationCustomization?.roadmap_view?.[0] || 'status'
    )
  }, [query?.type, organizationCustomization?.roadmap_view])

  const showStatus = useMemo(() => {
    return SHOW_STATUS_VIEW.includes(currentView as string)
  }, [currentView])

  const showDateBlock = useMemo(() => {
    if (isAdmin || organizationSetting?.show_roadmap_created_date) return true
    if (organizationSetting?.show_roadmap_etc_date && item.etc_date) return true
    return false
  }, [
    organizationSetting?.show_roadmap_created_date,
    organizationSetting?.show_roadmap_etc_date,
    item.etc_date,
  ])

  return (
    <PostLink
      className={clsx(
        'hn-rounded flex cursor-pointer flex-col justify-between space-y-1 overflow-hidden border border-gray6 p-2.5 hover:border-gray5',
        item.isDragging ? 'bg-gray3 ' : '',
        isUnderSublane ? 'bg-gray4 ' : 'bg-snow dark:bg-gray3'
      )}
      post={{ slug: item.slug }}
    >
      <div className='flex items-start gap-2'>
        <div className='flex flex-col gap-2'>
          <UpvoteButton
            size={organizationSetting?.downvotes ? 'sm' : 'md'}
            // @ts-ignore
            post={item as IPost}
            onUpdate={(newPost) =>
              postStore.updateSinglePost(item.slug, { ...newPost })
            }
          />
          <DownvoteButton
            size='sm'
            // @ts-ignore
            post={item as IPost}
            classNames={{
              buttonClassName: '',
            }}
            onUpdate={(newPost) =>
              postStore.updateSinglePost(item.slug, { ...newPost })
            }
          />
        </div>
        <div className='flex h-full w-full flex-col justify-between gap-1'>
          <p className='line-clamp-2 font-medium'>{item.title}</p>
          {showDateBlock && (
            <div className='flex items-center space-x-1 text-xs text-gray11 empty:hidden'>
              {(organizationSetting?.show_roadmap_created_date || isAdmin) && (
                <span>
                  {formatJSONTimestamp(item.post_created_at, 'LLL do')}
                </span>
              )}
              {((organizationSetting?.show_roadmap_created_date &&
                organizationSetting?.show_roadmap_etc_date) ||
                isAdmin) &&
                item.etc_date && <ArrowRight />}
              {item.etc_date &&
                (organizationSetting?.show_roadmap_etc_date || isAdmin) && (
                  <span>
                    {formatJSONTimestamp(
                      item?.etc_date?.timestamp || item.etc_date,
                      'LLL do'
                    )}
                  </span>
                )}
            </div>
          )}
          <div className='flex w-full items-center justify-end space-x-2'>
            <div className='flex grow items-center space-x-2 text-xs'>
              <span className='flex items-center space-x-1'>
                {showStatus && item.custom_status && (
                  <Tooltip asChild text={item.custom_status.title}>
                    <Status status={item.custom_status} iconOnly />
                  </Tooltip>
                )}
                <Badge
                  size='xxs'
                  variant='outline'
                  value={`${item.bucket.display_name || item.bucket.name}`}
                />
              </span>
            </div>
            <div className='flex items-center space-x-2'>
              {item.assignee && (
                <UserAvatar user={item.assignee} size='xxs' rounded />
              )}
              <div className='flex space-x-1'>
                <Tooltip
                  text={`${t('common.labels.prioritizationScore')} : ${
                    item?.score?.aggregate
                  }`}
                >
                  {objectHasProperty(item, 'score') && (
                    <Badge
                      size='xxs'
                      variant='outline'
                      value={humaizeScore(item?.score)}
                    />
                  )}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PostLink>
  )
}
